import React, { useEffect, useState, lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Loader as DefaultLoader, MITLoggedOut } from '@mit/hui'
import { initializeActiveRegion } from 'Api/authorization/RegionConfig'
import { useStoreActions, useStoreState } from 'Store'
import { AccountController } from 'Api/controller'
import { Loader } from './Loader'

export const Initialization: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [tokenExpired, setTokenExpired] = useState<boolean>(false)
  const { hasOutage } = useStoreState(state => state.regionModel)
  const { checkRegionUpdate } = useStoreActions(action => action.regionModel)
  const { updateProfile } = useStoreActions(action => action.profileModel)
  const { initializeStep, setRequiredSteps, setSkippableSteps } = useStoreActions(action => action.stepModel)
  const { setTexts, setStatus } = useStoreActions(action => action.globalModel)

  const location = window.location
  const path = location.pathname
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')
  const App = lazy(async () => await import('Components/App'))

  useEffect(() => {
    initializeActiveRegion(checkRegionUpdate)
      .then(async data => {
        sessionStorage.setItem('region_config', JSON.stringify(data))
        // unathenticated check token here
        if (token === null) {
          setHasAccess(false)
          setIsLoading(false)
        } else {
          sessionStorage.setItem('AccessToken', token)
          const accountController = new AccountController()
          accountController
            .getStatus()
            .then(data => {
              setTokenExpired(false)
              updateProfile({
                display_name: `${data.firstName ?? ''} ${data.lastName ?? ''}`,
                mitid: data.mitId ?? '',
                krb_name: data.krbName !== null && !(data.krbName ?? '').includes('#') ? data.krbName : '',
                first_name: data.firstName ?? '',
                last_name: data.lastName ?? ''
              })
              setStatus(data)
              initializeStep(data.nextStep)
              setRequiredSteps((data.steps as any) ?? [])
              setSkippableSteps((data.skippableSteps as any) ?? [])
              // initializeStep('completed')

              setIsLoading(false)
              setHasAccess(true)

              accountController
                .getTexts()
                .then(data => setTexts(data))
                .catch(error => console.error(error))
            })
            .catch(error => {
              console.error(error)
              // TODO: Show error page with message depending on response
              setIsLoading(false)
              setTokenExpired(true)
              setHasAccess(false)
            })
        }
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
        setHasAccess(false)
      })

    // eslint-disable-next-line
  }, [])

  if (isLoading || hasOutage) {
    return (
      <DefaultLoader
        contactEmail='reactTemplate'
        hasAccess={hasAccess}
        name='Kerberos Account Registration'
        isLoading={isLoading}
        type={hasOutage ? 'outage' : 'mit'}
      />
    )
  }

  if (!hasAccess || tokenExpired) {
    return <Loader type={tokenExpired ? 'expired' : 'no-access'} />
  }

  return (
    <Switch>
      {path !== '/logout' && (
        <Suspense fallback={<> </>}>
          <Route path='/' component={App} />
        </Suspense>
      )}
      <Route exact path='/logout' component={MITLoggedOut} />
    </Switch>
  )
}
