import { Action, action } from 'easy-peasy'
import { components } from 'Types'

type StepChoices = components['schemas']['Status']['nextStep']
export interface State {
  currentStep: number
  currentStepName: StepChoices
  currentStepSkippable: boolean
  requiredSteps: StepChoices[]
  skippableSteps: StepChoices[]
}
export interface Actions {
  initializeStep: Action<this, StepChoices>
  setRequiredSteps: Action<this, StepChoices[]>
  setSkippableSteps: Action<this, StepChoices[]>
  setStep: Action<this, number>
  changeStep: Action<this, number>
}
// eslint-disable-next-line
export interface Thunks {}

export interface StepModel extends State, Actions, Thunks {}

export const stepModel: StepModel = {
  currentStep: 0,
  currentStepName: 'kerb',
  currentStepSkippable: false,
  requiredSteps: [],
  skippableSteps: [],

  // Actions

  initializeStep: action((state, payload) => {
    let step = 0
    step = getStep(payload)
    state.currentStep = step
    state.currentStepName = payload
  }),
  setRequiredSteps: action((state, payload) => {
    state.requiredSteps = payload
  }),
  setSkippableSteps: action((state, payload) => {
    state.skippableSteps = payload
    state.currentStepSkippable = state.skippableSteps.filter(step => step === state.currentStepName).length > 0
  }),
  setStep: action((state, payload) => {
    state.currentStep = payload
  }),
  changeStep: action((state, payload) => {
    let currentStep: number = 0
    let currentStepName: StepChoices = 'kerb'

    state.requiredSteps.forEach((stepName, index) => {
      if (stepName === state.currentStepName) {
        const nextIndex = index + payload
        const nextStepName = state.requiredSteps[nextIndex]
        currentStep = getStep(nextStepName)
        currentStepName = nextStepName
      }
    })
    state.currentStep = currentStep
    state.currentStepName = currentStepName
    state.currentStepSkippable = state.skippableSteps.filter(step => step === currentStepName).length > 0
  })
}

const getStep = (stepName: StepChoices): number => {
  switch (stepName) {
    case 'kerb':
      return 0
    case 'password':
      return 1
    case 'picture':
      return 2
    case 'duo':
      return 3
    case 'completed_onboarding':
      return 4
    case 'completed':
      return 5
    default:
      return 0
  }
}
